var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"bg-claret-700 py-14 md:py-24 mt-10"},[_c('div',{staticClass:"content flex flex-col space-y-14 sm:flex-row sm:space-x-16 sm:space-y-0"},[_c('div',{staticClass:"flex flex-col w-full justify-between items-start"},[_vm._m(0),_vm._v(" "),_c('link-resolver',{staticClass:"cta-primary text-cta-primary text-center w-full sm:w-auto",attrs:{"url":"https://app.nurole.com/request"},nativeOn:{"click":function($event){_vm.gaEvent('Become a member'),
            _vm.trackMixpanelEvent('Become a member', {
              Block: 'Become a member',
            })}}},[_vm._v("\n        Become a member\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full items-start"},[_vm._m(1),_vm._v(" "),_c('nuxt-link',{staticClass:"cta-primary text-cta-primary text-center w-full sm:w-auto",attrs:{"to":"/contact"},nativeOn:{"click":function($event){_vm.gaEvent('Get in touch'),
            _vm.trackMixpanelEvent('Get in touch', {
              Block: 'Get in touch',
            })}}},[_vm._v("\n        Get in touch\n      ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"text-title-h2 text-white mb-4 md:mb-8"},[_vm._v("\n          Find your perfect board role\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-copy text-white mb-2 md:mb-3"},[_vm._v("\n          Membership of Nurole is by invitation only.\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-copy text-white mb-8"},[_vm._v("\n          You can ask an existing member to recommend you or you can request\n          an invitation by clicking the button below.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"text-title-h2 text-white mb-4 md:mb-8"},[_vm._v("\n          Find the best board talent\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-copy text-white mb-8"},[_vm._v("\n          Contact us today to discuss how we can help you find the perfect\n          candidate.\n        ")])])
}]

export { render, staticRenderFns }