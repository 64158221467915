import cookie from 'vue-cookies'
import { authApi } from '../lib/api'

export const state = () => ({
  isLoggedIn: false,
  navigation: {
    mobileMenuOpen: false,
    mobileMenuIndices: new Set(),
    desktopMenuIndex: -1,
  },
})

export const mutations = {
  setLoggedIn(state, value) {
    state.isLoggedIn = value
  },
  setNavigation(state, newState) {
    state.navigation = { ...state.navigation, ...newState }
  },
  toggleDesktopNavigation(state, index) {
    if (state.navigation.desktopMenuIndex === index) {
      state.navigation.desktopMenuIndex = -1
    } else {
      state.navigation.desktopMenuIndex = index
    }
  },
  toggleMobileNavigation(state, index) {
    const set = new Set(state.navigation.mobileMenuIndices)
    set.has(index) ? set.delete(index) : set.add(index)
    state.navigation.mobileMenuIndices = set
  },
}

export const actions = {
  navigateDesktop({ commit }, { index }) {
    commit('toggleDesktopNavigation', index)
  },
  navigateMobile({ commit }, { index }) {
    commit('toggleMobileNavigation', index)
  },
  openMobileMenu({ commit }) {
    commit('setNavigation', { mobileMenuOpen: true, desktopMenuIndex: -1 })
  },
  closeMobileMenu({ commit }) {
    commit('setNavigation', { mobileMenuOpen: false })
  },
  closeDesktopMenu({ commit }) {
    commit('setNavigation', { desktopMenuIndex: -1, mobileMenuOpen: false })
  },
  async logout({ commit }) {
    commit('setLoggedIn', false)
    await authApi.post('/logout', {
      withCredentials: true,
    })
  },
  getInitialLoggedInState({ commit }) {
    const isLoggedIn =
      process.browser &&
      cookie.get(`should_redirect_to_app_${process.env.rackEnvironment}`) ===
        'true'
    commit('setLoggedIn', isLoggedIn)
  },
}
