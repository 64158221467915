
import LinkResolver from '~/components/LinkResolver.vue'

export default {
  name: 'NavHeadingLinks',
  components: { LinkResolver },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
