
import { trackChatStart } from '~/lib/linkedInTracker'

export default {
  data() {
    return {
      data: {},
      cookiesAccepted: false,
    }
  },

  async fetch() {
    const { data } = await this.$prismic.api.getByUID(
      'header_and_footer',
      'header-and-footer'
    )

    this.data = data
  },
  computed: {
    version() {
      return `${process.env.version}`
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    filteredFooterColumnLinks() {
      return this.data.footer_links_col_1
        ? this.data.footer_links_col_1.filter(
            (item) => item.label !== this.data.footer_cta_2_label_logged_in
          )
        : []
    },
  },
  mounted() {
    if (process.browser) {
      this.cookiesAccepted = this.$cookies.get('cookieAgreed') === 'true'
    }
  },
  methods: {
    waitForHubspotApiLoad() {
      if (typeof window.HubSpotConversations !== 'undefined') {
        window.HubSpotConversations.on('conversationStarted', () => {
          trackChatStart()
        })
      } else {
        setTimeout(this.waitForHubspotApiLoad, 250)
      }
    },
  },
}
