// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/SourceSansPro-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/SourceSansPro-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/SourceSansPro-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/PlayfairDisplay-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/PlayfairDisplay-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body,html{--tw-bg-opacity:1;background-color:#fefaef;background-color:rgba(254,250,239,var(--tw-bg-opacity));--tw-text-opacity:1;color:#242726;color:rgba(36,39,38,var(--tw-text-opacity))}@font-face{font-display:swap;font-family:\"Source Sans Pro\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Source Sans Pro\";font-style:normal;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Source Sans Pro\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Playfair Display\";font-style:normal;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}@font-face{font-display:swap;font-family:\"Playfair Display\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\")}@media print{.no-print,.no-print *{display:none!important}body{background-color:#fff!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
