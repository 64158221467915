
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  computed: {
    isInternal() {
      return (
        this.url.includes('www.nurole.com') ||
        this.url.includes('://nurole.com')
      )
    },
    isApp() {
      return this.url.includes('app.nurole.com')
    },
    isAccount() {
      return this.url.includes('account.nurole.com')
    },
    path() {
      const match = this.url.match(/(.com.*?\/)(.*)/)

      return match ? `/${match[2]}` : '/'
    },
    resolvedUrl() {
      if (this.isInternal) return this.path
      if (this.isApp) return `${process.env.nuroleAppBaseUrl}${this.path}`
      if (this.isAccount) return `${process.env.nuroleAccountUrl}${this.path}`
      return this.url
    },
  },
}
