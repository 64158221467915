
export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  head: {
    title: 'Page Not Found | Nurole',
  },

  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
}
