import { mixpanel } from '../plugins/mixpanel.client'
export default function ({ route }) {
  if (process.browser && window) {
    window.analytics?.identify()
    window.analytics?.page(route.path)

    if (mixpanel.__loaded) {
      mixpanel.track('Page view', {
        Platform: 'Marketing Site',
        path: route.path,
        mame: route.params.slug || route.name,
      })
    }

    const _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', route.path])
    _hsq.push(['trackPageView'])
  }
}
