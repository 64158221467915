
export default {
  name: 'NavSubHeading',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    isExpanded() {
      return this.open
    },
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
}
