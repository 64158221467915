import Vue from 'vue'
import { mixpanel } from '../plugins/mixpanel.client'

Vue.mixin({
  methods: {
    gaEvent(target) {
      window.gtag('event', 'cta_click', { target })
    },
    trackMixpanelEvent(eventName, properties) {
      const defaults = { Platform: 'Marketing Site' }
      mixpanel.track(eventName, { ...defaults, ...properties })
    },
    identifyLoggedInUser(loginId, email) {
      mixpanel.identify(loginId)
      window.clarity('set', 'login_id', loginId)
      window.clarity('set', 'email', email)
      window.gtag('set', { user_id: loginId })
    },
  },
})
