declare global {
  interface Window {
    lintrk: Function
  }
}

export function trackChatStart() {
  trackEvent('1255602')
}

export function trackContactFormSubmit() {
  trackEvent('379778')
}

export function trackContactEmailClick() {
  trackEvent('1255586')
}

export function trackPricingGuideSubmit() {
  trackEvent('379762')
}

export function trackPostRoleSubmit() {
  trackEvent('379762')
}

function trackEvent(id: string) {
  window.lintrk('track', { conversion_id: id })
}
