
import { authApi } from '~/lib/api'

export default {
  async mounted() {
    try {
      const { data } = await authApi.get('/status')
      if (data.login_id && data.email) {
        this.identifyLoggedInUser(data.login_id, data.email)
      }
    } catch (error) {
      if (error?.response?.status !== 401) {
        this.$sentry.captureException(error)
      }
    }
  },
}
