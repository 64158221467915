
export default {
  name: 'NavHeading',
  props: {
    title: {
      type: String,
      default: '',
    },
    menuIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isDesktop: false,
    }
  },
  computed: {
    isExpanded() {
      if (this.isDesktop) {
        return this.$store.state.navigation.desktopMenuIndex === this.menuIndex
      } else {
        return this.$store.state.navigation.mobileMenuIndices.has(
          this.menuIndex
        )
      }
    },
  },
  mounted() {
    const mql = window.matchMedia('(min-width: 1280px)')
    this.isDesktop = mql.matches
    mql.onchange = (e) => {
      this.isDesktop = e.matches
    }
  },
  methods: {
    onClick() {
      if (this.isDesktop) {
        this.$store.dispatch('navigateDesktop', { index: this.menuIndex })
      } else {
        this.$store.dispatch('navigateMobile', { index: this.menuIndex })
      }
    },
  },
}
