
import LinkResolver from '~/components/LinkResolver.vue'

export default {
  name: 'NavHighlight',
  components: { LinkResolver },
  props: {
    slice: {
      type: Object,
      default: null,
    },
  },
}
