export default function ({ route, redirect }) {
  if (
    ['/login', '/logout', '/signin', '/signout'].some((path) =>
      route.path.startsWith(path)
    )
  ) {
    return redirect(`${process.env.nuroleAccountUrl}${route.fullPath}`)
  }

  if (
    [
      '/recommend',
      '/request',
      '/roles',
      '/account',
      '/unsubscribe',
      '/forgotten',
      '/reset',
      '/changeemail',
      '/invite',
      '/reconfigure',
      '/onboarding',
      '/postpone',
    ].some((path) => route.path.startsWith(path))
  ) {
    return redirect(`${process.env.nuroleAppBaseUrl}${route.fullPath}`)
  }

  if (route.path.includes('careers-at-nurole')) {
    return redirect('https://careers.nurole.com')
  }

  // to fix links such as https://ww2.nurole.com/members-faqs/https:/www.nurole.com/should-i-apply-guide
  if (route.path.includes('/https:/www.nurole.com')) {
    return redirect(route.fullPath.replace('/https:/www.nurole.com', ''))
  }

  if (route.path.includes('/www.nurole.com')) {
    return redirect(route.fullPath.replace('/www.nurole.com', ''))
  }

  if (route.path.startsWith('/success-stories/news-and-guides')) {
    return redirect(route.fullPath.replace('/success-stories', ''))
  }

  if (route.path.startsWith('/news-and-guides/guides/')) {
    return redirect(
      route.fullPath.replace('/news-and-guides/guides/', '/news-and-guides/')
    )
  }

  if (route.path.includes('news_and_guides')) {
    if (route.path.includes('news_and_guides/categories/')) {
      const categoryRoute = route.fullPath.replace(
        'news_and_guides/categories/',
        'news-and-guides/category/'
      )
      return redirect(categoryRoute.replace('_', '-'))
    }

    return redirect(
      route.fullPath.replace('news_and_guides', 'news-and-guides')
    )
  }

  if (route.path.startsWith('/news-and-guides/categories/')) {
    const categoryRoute = route.fullPath.replace('/categories/', '/category/')
    return redirect(categoryRoute.replace('_', '-'))
  }

  if (route.path.includes('success_stories')) {
    return redirect(
      route.fullPath.replace('success_stories', 'success-stories')
    )
  }

  if (route.path.startsWith('/employers/')) {
    return redirect(route.fullPath.replace('/employers/', '/'))
  }

  if (route.path.startsWith('/candidates/')) {
    return redirect(route.fullPath.replace('/candidates/', '/'))
  }

  if (route.path.startsWith('/partners/')) {
    return redirect(route.fullPath.replace('/partners/', '/'))
  }

  if (route.path.startsWith('/members-faqs')) {
    return redirect('https://help.nurole.com')
  }

  if (route.path === '/for-organisations') {
    return redirect(
      route.fullPath.replace('/for-organisations', '/find-your-solution')
    )
  }
}
