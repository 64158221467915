
export default {
  name: 'Cookies',

  data() {
    return {
      show: false,
    }
  },

  async created() {
    if (process.browser) {
      const cookies = await this.$cookies.get('cookieAgreed')
      if (cookies !== 'true') this.show = true
    }
  },

  methods: {
    close() {
      if (process.browser) {
        this.$cookies.set('cookieAgreed', 'true', Infinity, null, 'nurole.com')
        this.show = false
      }
    },
  },
}
