import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.mixpanelToken, {
  debug: process.env.rackEnvironment === 'development',

  loaded(mixpanel) {
    if (process.browser) {
      mixpanel.track('Page view', {
        Platform: 'Marketing Site',
        path: window.location.pathname,
        name:
          window.location.pathname === '/'
            ? 'index'
            : window.location.pathname.slice(1),
      })
    }
  },
})

export { mixpanel }
