
import ClickOutside from 'vue-click-outside'
import LinkResolver from '~/components/LinkResolver.vue'
import NavHeading from '~/components/NavHeading.vue'
import NavHighlight from '~/components/NavHighlight.vue'
import NavSubHeading from '~/components/NavSubHeading.vue'
import NavHeadingLinks from '~/components/NavHeadingLinks.vue'

export default {
  name: 'Navigation',
  components: {
    NavHeadingLinks,
    NavSubHeading,
    NavHighlight,
    NavHeading,
    LinkResolver,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      data: [],
    }
  },
  async fetch() {
    const document = await this.$prismic.api.query(
      this.$prismic.predicates.at('document.type', 'heading')
    )
    this.data = document.results
      .sort((a, b) => a.data.order - b.data.order)
      .map((result) => result.data)
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    nuroleAppBaseUrl() {
      return process.env.nuroleAppBaseUrl
    },
    isMobileMenuOpen() {
      return this.$store.state.navigation.mobileMenuOpen
    },
    nuroleAccountUrl() {
      return process.env.nuroleAccountUrl
    },
  },
  mounted() {
    this.$store.dispatch('getInitialLoggedInState')

    document.addEventListener('scroll', (_) => {
      if (this.$refs.topRedBar === undefined) return
      let ticking // for throttling

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY > 80) {
            this.$refs.topRedBar.classList.add('xl:hidden')
          } else if (window.scrollY === 0) {
            this.$refs.topRedBar.classList.remove('xl:hidden')
          }
          ticking = false
        })
        ticking = true
      }
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    closeMenu() {
      this.$store.dispatch('closeDesktopMenu')
      this.$store.dispatch('closeMobileMenu')
    },
    openMobileMenu() {
      this.$store.dispatch('openMobileMenu')
    },
  },
}
