import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=9d43a398"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HowCanWeHelp: require('/home/runner/work/marketing/marketing/components/HowCanWeHelp.vue').default,LinkResolver: require('/home/runner/work/marketing/marketing/components/LinkResolver.vue').default,Footer: require('/home/runner/work/marketing/marketing/components/Footer.vue').default})
